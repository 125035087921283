import { Injectable } from "@angular/core";
import { AuthService } from './auth.service';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanActivate
} from "@angular/router";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class AuthGuard implements CanActivate {
  public currentUrl: string;

  constructor(private authService: AuthService, private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    this.currentUrl = state.url;
    return this.checkLogin(this.currentUrl);
  }

  checkLogin(url: string) {
    const isAuth = localStorage.getItem('token');
    if (!isAuth) {
      this.router.navigate([""]);
      // return false;
    } else {
        // this.router.navigate(["/home"]);
        return true;
    }
  }
}
