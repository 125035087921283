import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  faHome,
  faSearch,
  faShare,
  faUserCircle,
  faUserFriends,
} from "@fortawesome/free-solid-svg-icons"; import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  faHome = faHome;
  faSearch = faSearch;
  faUserCircle = faUserCircle;
  faUserFriends = faUserFriends;
  faShare = faShare;
  allUsers;
  constructor(private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,) { }

  ngOnInit() {
    this.authService.getAllUsers().subscribe(data => {
      console.log(data, 'get all usersss');
      this.allUsers = data.data
    })
  }

  blockStatus: boolean
  switchClicked(event, data) {
    this.blockStatus = event.srcElement.checked;
    console.log(this.blockStatus, 'blockkk');
    this.authService.updateIsBlocked(data._id, this.blockStatus).subscribe(res => {
      console.log(res, 'responseeee')
    })
  }

}
