export class NewContent {
  constructor(
    public category: String,
    public name: String,
    public imgsrc: String,
    public desp: String,
    public rating: number
  ) {
    this.category = category;
    this.name = name;
    this.imgsrc = imgsrc;
    this.desp = desp;
    this.rating = rating;
  }
}
