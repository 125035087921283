import { Component, OnInit } from "@angular/core";
import { faAngleLeft, faTrash } from "@fortawesome/free-solid-svg-icons";
import { AuthService } from "src/app/services/auth.service";
import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from "@angular/common";
import { Router } from "@angular/router";

@Component({
  selector: "app-stars-trending",
  templateUrl: "./stars-trending.component.html",
  styleUrls: ["./stars-trending.component.scss"],
})
export class StarsTrendingComponent implements OnInit {
  faAngleLeft = faAngleLeft;
  faTrash = faTrash;
  trendingStars = [];
  numClass: any = 1;
  id;
  postId;
  userRole: any;
  deletePostId: any;
  deleteIndex: any;
  constructor(private authService: AuthService, public location: Location, private router: Router,) {
    this.authService.Post.subscribe(() => {
      this.getPosts();
    });
  }

  ngOnInit() {
    this.id = localStorage.getItem("userId");
    this.userRole = localStorage.getItem("userType");
    this.getPosts();
  }
  createdById;
  _id;
  getPosts() {
    this.authService.getAllPosts().subscribe((response: any) => {
      this.numClass = 1;
      this.trendingStars = response.data;
      // this.createdById = this.trendingStars[0].createdBy;
      // this._id = this.trendingStars[0]._id;
    });
  }
  navigateBack() {
    this.location.back();
  }
  rating(value, id) {
    this.numClass = value;
    let data = {
      userId: this.id,
      rating: this.numClass,
    };
    this.authService.likePost(id, data).subscribe((response) => {
      this.getPosts();
    });
  }
  captureDeleteId(id, index) {
    this.deletePostId = id;
    this.deleteIndex = index;
  }
  deletePost(data) {
    if (data === 'confirm') {
      console.log(this.deletePostId._id, 'post id from starrr');
      this.authService.deletePost(this.deletePostId._id).subscribe((response) => {
        console.log('response', response);
        this.trendingStars.splice(this.deleteIndex, 1);
        this.deletePostId = null;
        this.deleteIndex = null;
      });
      // this.router.navigateByUrl("/home");
    }
  }

  blockPost(i) {
    this._id = this.trendingStars[i]._id;
    this.authService.blockPost(this._id).subscribe(data => {
      this.router.navigate(["/home"]);
    })
  }
  blockUser(i) {
    this.createdById = this.trendingStars[i].createdBy;
    this.authService.blockUser(this.createdById).subscribe(data => {
      this.router.navigate(["/home"]);
    })
  }
}
