import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FileUploadModule } from "ng2-file-upload";
// import { NgFlashMessagesModule } from 'ng-flash-messages';
import { NgFlashMessagesModule } from "ng-flash-messages";
import { NgSelectModule } from "@ng-select/ng-select";

//components
import { AppComponent } from "./app.component";
import { LoginComponent } from "./components/login/login.component";
import { SignupComponent } from "./components/signup/signup.component";
import { HomepageComponent } from "./components/homepage/homepage.component";

import { ConfrimEqualValidator } from "./shared/confrim-equal-validator-directive";
import { Location } from "@angular/common";

// Services
import { AuthService } from "./services/auth.service";

import { HttpClientModule } from "@angular/common/http";

//pipes
import { FilterPipe } from "./pipes/filter.pipe";

// material design
import { MatInputModule } from "@angular/material/input";

//icons
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { ForewordComponent } from "./components/foreword/foreword.component";
import { NavigationComponent } from "./components/navigation/navigation.component";
import { NewContentComponent } from "./components/new-content/new-content.component";
import { SearchdetailsComponent } from "./components/search/searchdetails/searchdetails.component";
import { StarLiveFeedComponent } from "./components/homepage/star-live-feed/star-live-feed.component";
import { SearchComponent } from "./components/search/search.component";
import { StarsTrendingComponent } from "./components/stars-trending/stars-trending.component";
import { ForgetPasswordComponent } from "./components/forget-password/forget-password.component";
import { MyProfileComponent } from "./components/my-profile/my-profile.component";
import { FeedbackComponent } from "./components/feedback/feedback.component";
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";
import { AuthGuard } from "./services/auth.guard.service";
import { NewPostComponent } from "./components/new-post/new-post.component";
import { AdminComponent } from './components/admin/admin.component';
import { TermsOfUseComponent } from './components/terms-of-use/terms-of-use.component';
import { FooterLogoComponent } from './components/footer-logo/footer-logo.component';
import { PrivacyComponent } from './components/privacy/privacy.component';

@NgModule({
  declarations: [
    // pipes
    FilterPipe,

    // components
    AppComponent,
    LoginComponent,
    HomepageComponent,
    SignupComponent,
    ForewordComponent,
    NavigationComponent,
    NewContentComponent,
    SearchdetailsComponent,
    StarLiveFeedComponent,
    SearchComponent,
    StarsTrendingComponent,
    ForgetPasswordComponent,
    MyProfileComponent,
    FeedbackComponent,
    ResetPasswordComponent,
    ConfrimEqualValidator,
    NewPostComponent,
    AdminComponent,
    TermsOfUseComponent,
    FooterLogoComponent,
    PrivacyComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    MatInputModule,
    FontAwesomeModule,
    HttpClientModule,
    ReactiveFormsModule,
    FileUploadModule,
    NgFlashMessagesModule.forRoot(),
    NgSelectModule
  ],
  providers: [AuthService, AuthGuard, Location],
  bootstrap: [AppComponent],
})
export class AppModule { }
