import { Component, OnInit } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";

import { AuthService } from "../../services/auth.service";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { NgFlashMessageService } from "ng-flash-messages";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  faEye = faEye;
  user;
  // authService: AuthService;
  // router: Router;

  constructor(public authService: AuthService) {}

  ngOnInit() {
    this.user = this.authService.newuser;
  }

  submit(f: NgForm) {
    this.authService.loginUser(f.value);
  }

  hide: boolean = true;

  showPassword() {
    this.hide = !this.hide;
  }
}
