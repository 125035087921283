import { Directive, Input } from "@angular/core";
import { Validator, NG_VALIDATORS, AbstractControl } from "@angular/forms";

@Directive({
  selector: "[mustMatch]",
  providers: [
    { provide: NG_VALIDATORS, useExisting: ConfrimEqualValidator, multi: true },
  ],
})
export class ConfrimEqualValidator implements Validator {
  @Input() mustMatch: String;

  validate(control): { [key: string]: any } | null {
    const controlToCompare = control.parent.get(this.mustMatch);
    if (controlToCompare && controlToCompare.value !== control.value) {
      return { notEqual: true };
    }
    return null;
  }
}
