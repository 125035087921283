import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
export class SearchComponent implements OnInit {
  searchText;
  data = [];
  textSearch = {
    userId: localStorage.getItem('userId')
  }

  clearSearch() {
    this.searchText = "";
  }

  constructor(private authservice: AuthService, private router: Router) {
    this.authservice.contentEmitter.subscribe(msg => {
      this.getPosts();
    })
  }

  ngOnInit() {
    this.getPosts();
  }

  getPosts() {
    this.authservice.getTopTenPosts(this.textSearch).subscribe((response) => {
      let result = response.data.map((o) => ({
        id: o._id,
        name: o.name,
        category: o.category,
        imageUrl: o.imageUrl,
      }));
      this.data = result;
    });
  }
  routing() {
    this.searchText = "";
    this.router.navigate(["/new-content"]);
  }
}
