import { Pipe, PipeTransform } from "@angular/core";
@Pipe({
  name: "filter",
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) return [];
    if (!searchText) return [];
    searchText = searchText.toLowerCase();
    return items.filter((it) => {
      if (it.category.toLowerCase().includes(searchText)) {
        return it.category.toLowerCase().includes(searchText);
      } else if (it.name.toLowerCase().includes(searchText)) {
        return it.name.toLowerCase().includes(searchText);
      }
      //   return it.toLowerCase().includes(searchText);
    });
  }
}
