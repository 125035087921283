import { Component, OnInit } from "@angular/core";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { AuthService } from "src/app/services/auth.service";
import { ActivatedRoute, Router, Params } from "@angular/router";
import { Observable, of } from "rxjs";
import { Location } from "@angular/common";
import { switchMap } from "rxjs/operators";
import { NgFlashMessageService } from "ng-flash-messages";
import { throwMatDialogContentAlreadyAttachedError } from "@angular/material";
@Component({
  selector: "app-searchdetails",
  templateUrl: "./searchdetails.component.html",
  styleUrls: ["./searchdetails.component.scss"],
})
export class SearchdetailsComponent implements OnInit {
  faAngleLeft = faAngleLeft;
  numClass: number = 0;
  data: any = {
    name: String,
    category: String,
    imageUrl: String,
    description: String,
    userId: String,
  };
  id;
  ratingValue: number;
  userRole: any;
  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private ngFlashMessageService: NgFlashMessageService,
    private location: Location
  ) { }

  ngOnInit() {
    this.userRole = localStorage.getItem("userType");
    this.route.params.subscribe((params) => {
      this.id = this.route.snapshot.paramMap.get("id");
    });
    this.getContent();
  }

  refresh() {
    location.reload();
  }

  rating(value) {
    let localId = localStorage.getItem("userId");
    let index = this.data.userRating.findIndex((ele) => ele.userId == localId);
    if (index != -1) {
      let rating = value - this.data.userRating[index].rating;
      this.data.rating = value;
      this.data.userRating[index].rating = value;
      this.data.updateRatingToUserValue = value;
      this.data.updateRatingToUserId = this.data.userRating[index]._id;
      this.numClass = this.data.userRating[index].rating;
      this.data.userId = localId;
      this.authService
        .updateRating(this.id, this.data)
        .subscribe((response: any) => {
          this.ngFlashMessageService.showFlashMessage({
            messages: ["Rating updated"],
            dismissible: true,
            timeout: 15000,
            type: "danger",
          });
          this.getContent();
          // if (response.status == 200) {
          //   this.ratingDiv = false;
          // }
        });
    } else {
      this.data.insertRatingToUserValue = value;
      this.data.insertIdtoUserValue = localId;
      this.data.rating = this.data.rating + value;

      this.numClass = value;

      this.authService
        .insertRatingfromNewUser(this.id, this.data)
        .subscribe((response: any) => {
          this.ngFlashMessageService.showFlashMessage({
            messages: ["Rating updated"],
            dismissible: true,
            timeout: 15000,
            type: "danger",
          });
          // if (response.status == 200) {
          //   this.ratingDiv = false;
          // }
        });
      this.getContent();
    }
  }
  contentId;
  createdById;
  getContent() {
    this.authService.getContentById(this.id).subscribe((product) => {
      this.data = product.data[0];
      this.contentId = this.data._id;
      this.createdById = this.data.createdBy;
      this.ratingValue = this.data.rating;
      let localId = localStorage.getItem("userId");
      let index = this.data.userRating.findIndex(
        (ele) => ele.userId == localId
      );
      if (index != -1) {
        this.numClass = this.data.userRating[index].rating;
      }
    });
  }
  deleteContent() {
    this.authService.deleteContent(this.id).subscribe((response) => {
      console.log('response', response);
    });
    this.router.navigateByUrl("/home");
  }
  blockContent() {
    this.authService.blockContent(this.contentId).subscribe(data => {
      this.router.navigate(["/home"]);
    })
  }

  blockUser() {
    this.authService.blockUser(this.createdById).subscribe(data => {
      this.router.navigate(["/home"]);
    })
  }

  navigateBack() {
    this.location.back();
  }
}
