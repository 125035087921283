import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
// import { conrimEqualValidator } from "../../shared/confrim-equal-validator-directive";
import { AuthService } from "../../services/auth.service";
import { NgFlashMessageService } from "ng-flash-messages";
import { Router } from "@angular/router";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
  constructor(
    public authService: AuthService,
    public router: Router,
    private ngFlashMessageService: NgFlashMessageService
  ) {}

  ngOnInit() {}

  submit(f: NgForm) {
    const urlSplit = window.location.href.split("?key=")[1];
    const tokenUrl = urlSplit.split("%22")[0];
    this.authService.setNewPassword(f.value, tokenUrl).subscribe((data) => {
      if (data["status"] == 200) {
        this.ngFlashMessageService.showFlashMessage({
          messages: [data["message"]],
          dismissible: true,
          timeout: 15000,
          type: "success",
        });
        this.router.navigateByUrl("/");
      } else {
        this.ngFlashMessageService.showFlashMessage({
          messages: [data["message"]],
          dismissible: true,
          timeout: 8000,
          type: "danger",
        });
      }
    });
  }
}
