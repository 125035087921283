import { Component, OnInit } from "@angular/core";
import {
  faAngleLeft,
  faShare,
  faLightbulb,
} from "@fortawesome/free-solid-svg-icons";
import { AuthService } from "src/app/services/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { NgFlashMessageService } from "ng-flash-messages";
import { Location } from "@angular/common";
import { ThrowStmt } from "@angular/compiler";

@Component({
  selector: "app-star-live-feed",
  templateUrl: "./star-live-feed.component.html",
  styleUrls: ["./star-live-feed.component.scss"],
})
export class StarLiveFeedComponent implements OnInit {
  faAngleLeft = faAngleLeft;
  faShare = faShare;
  id: string;
  disableBtn: boolean;
  numClass: string = "Unlike";
  livefeed: any = {
    name: String,
    category: String,
    imageUrl: String,
    description: String,
  };
  deletePostId: any;
  deleteIndex: any;
  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private ngFlashMessageService: NgFlashMessageService,
    public location: Location
  ) { }

  userRole;
  contentId;
  createdById;
  ngOnInit() {
    this.userRole = localStorage.getItem("userType");
    this.route.params.subscribe((params) => {
      this.id = this.route.snapshot.paramMap.get("id");
      this.authService.getContentById(this.id).subscribe((product) => {
        this.livefeed = product.data[0];
        console.log(this.livefeed, 'livefed@@@@@@');
        this.contentId = this.livefeed._id;
        this.createdById = this.livefeed.createdBy;
      });
    });
  }

  // captureDeleteId(id, index) {
  //   this.deletePostId = id;
  //   this.deleteIndex = index;
  //   console.log(this.deletePostId, this.deleteIndex, 'captureeeee')
  // }

  deleteContent() {
    // if (data === 'confirm') {
    //   console.log(this.deletePostId._id, 'post id from starrr');
    this.authService.deleteContent(this.id).subscribe((response) => {
      console.log('response', response);
      this.deletePostId = null;
      this.deleteIndex = null;
    });
    this.router.navigateByUrl("/home");
    // }
  }

  blockContent() {
    this.authService.blockContent(this.contentId).subscribe(data => {
      this.router.navigate(["/home"]);
    })
  }

  blockUser() {
    this.authService.blockUser(this.createdById).subscribe(data => {
      this.router.navigate(["/home"]);
    })
  }
  navigateBack() {
    this.location.back();
  }
}
