import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-feedback",
  templateUrl: "./feedback.component.html",
  styleUrls: ["./feedback.component.scss"],
})
export class FeedbackComponent implements OnInit {
  feedbackForm: FormGroup;

  constructor( private fb: FormBuilder, private authService: AuthService ) {}

  ngOnInit() {
    this.feedbackForm = this.fb.group({
      feedbackDiv : ["", [Validators.required]]
    })
  }
  onSignUpSubmit(){
    let data = {
      id : localStorage.getItem('userId'),
      message : this.feedbackForm.value['feedbackDiv']
    }
    this.authService.feedbackForm(data,data.id).subscribe((response)=>{
      console.log("response",response);
      
    })
  }
}
