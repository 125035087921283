import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./components/login/login.component";
import { HomepageComponent } from "./components/homepage/homepage.component";
import { SignupComponent } from "./components/signup/signup.component";
import { ForewordComponent } from "./components/foreword/foreword.component";
import { NewContentComponent } from "./components/new-content/new-content.component";
import { SearchdetailsComponent } from "./components/search/searchdetails/searchdetails.component";
import { StarLiveFeedComponent } from "./components/homepage/star-live-feed/star-live-feed.component";
import { SearchComponent } from "./components/search/search.component";
import { StarsTrendingComponent } from "./components/stars-trending/stars-trending.component";
import { ForgetPasswordComponent } from "./components/forget-password/forget-password.component";
import { MyProfileComponent } from "./components/my-profile/my-profile.component";
import { FeedbackComponent } from "./components/feedback/feedback.component";
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";

// route guard
import { AuthGuard } from "./services/auth.guard.service";
import { NewPostComponent } from "./components/new-post/new-post.component";
import { AdminComponent } from "./components/admin/admin.component";
import { TermsOfUseComponent } from "./components/terms-of-use/terms-of-use.component";
import { PrivacyComponent } from "./components/privacy/privacy.component";

const routes: Routes = [
  { path: "", component: LoginComponent },
  { path: "signup", component: SignupComponent },
  { path: "home", component: HomepageComponent, canActivate: [AuthGuard] },
  { path: "foreword", component: ForewordComponent, canActivate: [AuthGuard] },
  { path: "search", component: SearchComponent, canActivate: [AuthGuard] },
  {
    path: "search-details/:id",
    component: SearchdetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "new-content",
    component: NewContentComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "star-live-feed/:id",
    component: StarLiveFeedComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "stars-trending",
    component: StarsTrendingComponent,
    canActivate: [AuthGuard],
  },
  { path: "forget-password", component: ForgetPasswordComponent },
  { path: "reset-password", component: ResetPasswordComponent },
  {
    path: "my-profile",
    component: MyProfileComponent,
    canActivate: [AuthGuard],
  },
  { path: "new-post", component: NewPostComponent, canActivate: [AuthGuard] },
  { path: "support", component: FeedbackComponent },
  { path: "admin", component: AdminComponent },
  { path: "terms-of-use", component: TermsOfUseComponent },
  { path: "privacy", component: PrivacyComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule { }
