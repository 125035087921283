import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../services/auth.service";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { NgFlashMessageService } from "ng-flash-messages";

@Component({
  selector: "app-forget-password",
  templateUrl: "./forget-password.component.html",
  styleUrls: ["./forget-password.component.scss"],
})
export class ForgetPasswordComponent implements OnInit {
  constructor(
    public authService: AuthService,
    public router: Router,
    private ngFlashMessageService: NgFlashMessageService
  ) {
    this.router = router;
    this.authService = authService;
  }
  email;

  ngOnInit() {}

  submit(f: NgForm) {
    this.authService.forgetpasswordweb(f.value).subscribe((data) => {
      if (data.status == 200) {
        // this.router.navigateByUrl("/reset-password");
        this.ngFlashMessageService.showFlashMessage({
          // Array of messages each will be displayed in new line
          messages: [data["message"]],
          dismissible: true,
          timeout: 20000,
          // Type of flash message, it defaults to info and success, warning, danger types can also be used
          type: "success",
        });
        this.router.navigateByUrl("/reset-password");
      } else {
        this.ngFlashMessageService.showFlashMessage({
          // Array of messages each will be displayed in new line
          messages: [data["message"]],
          dismissible: true,
          timeout: 3000,
          // Type of flash message, it defaults to info and success, warning, danger types can also be used
          type: "danger",
        });
      }
    });
  }
}
