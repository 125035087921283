import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-my-profile",
  templateUrl: "./my-profile.component.html",
  styleUrls: ["./my-profile.component.scss"],
})
export class MyProfileComponent implements OnInit {
  constructor(private authService: AuthService) {}
  userData: any = {
    email: String,
    username: String,
    name: String,
  };
  ngOnInit() {
    this.authService
      .getUserById(localStorage.getItem("userId"))
      .subscribe((response: any) => {
        this.userData = response.data;
      });
  }
  logOut() {
    this.authService.logout();
  }
}
