import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../services/auth.service";
import { faAngleLeft, faEye } from "@fortawesome/free-solid-svg-icons";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { NgFlashMessageService } from "ng-flash-messages";

@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.scss"],
})
export class SignupComponent implements OnInit {
  faAngleLeft = faAngleLeft;
  faEye = faEye;
  user;

  constructor(
    public authService: AuthService,
    public router: Router,
    private ngFlashMessageService: NgFlashMessageService
  ) {
    this.authService = authService;
    this.router = this.router;
  }
  ngOnInit() {
    this.user = this.authService.newuser;
  }

  hide: boolean = true;

  showPassword() {
    this.hide = !this.hide;
  }
  submit(f: NgForm) {
    this.authService.registerUser(f.value).subscribe((data) => {
      if (data["status"] == 200) {
        // this.router.navigateByUrl("/foreword");
        this.ngFlashMessageService.showFlashMessage({
          // Array of messages each will be displayed in new line
          messages: [data["message"]],
          dismissible: true,
          timeout: 15000,
          // Type of flash message, it defaults to info and success, warning, danger types can also be used
          type: "danger",
        });
        this.router.navigateByUrl("/");
      } else {
        this.ngFlashMessageService.showFlashMessage({
          // Array of messages each will be displayed in new line
          messages: [data["message"]],
          dismissible: true,
          timeout: 8000,
          // Type of flash message, it defaults to info and success, warning, danger types can also be used
          type: "danger",
        });
      }
    });
  }
}
