import { Component, OnInit, OnDestroy } from "@angular/core";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { NewContent } from "../../newContent.modal";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { AuthService } from "../../services/auth.service";
import { environment } from "src/environments/environment";
import { FileUploader, FileItem, FileUploadModule } from "ng2-file-upload";
import { NgFlashMessageService } from "ng-flash-messages";
import { NgSelectModule, NgOption } from "@ng-select/ng-select";
import { NavigationEnd, Router } from "@angular/router";
import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from "@angular/common";

@Component({
  selector: "app-new-content",
  templateUrl: "./new-content.component.html",
  styleUrls: ["./new-content.component.scss"],
})
export class NewContentComponent implements OnInit, OnDestroy {
  faAngleLeft = faAngleLeft;
  circleDisplay: boolean = false;
  numClass: any = 0;
  newContentForm: FormGroup;
  submitted: boolean;
  ratingNo: number;
  uploader: FileUploader;
  filePreArr = [];
  categories = [
    "Actors/Actresses",
    "Apps",
    "Automotive",
    "Books",
    "Clothing",
    "Criminals",
    "Current Events",
    "Dining",
    "Education",
    "Electronics",
    "Entertainment",
    "Footwear",
    "Furniture",
    "Grocery",
    "Government",
    "Health ",
    "Industrial ",
    "Jewelry",
    "Light",
    "Media",
    "Money",
    "Movies",
    "Music",
    "News",
    "Olympics",
    "Pets",
    "Politics",
    "Pop Culture",
    "Quotes",
    "Recreation",
    "Religion/spirituality",
    "Schools",
    "Social Media",
    "Sports",
    "Techonology",
    "Toys",
    "TV Shows",
    "Weather/Environment",
  ];
  selectedCategory = "Select a Category";
  mySubscription: any;

  constructor(
    private fb: FormBuilder,
    private authservice: AuthService,
    private ngFlashMessageService: NgFlashMessageService,
    private router: Router,
    private location: Location
  ) {}

  ngOnInit() {
    this.selectedCategory = "Select a Category";

    // this.uploader = new FileUploader({
    //   url: environment.apiUrl + "uploadFile",
    //   // authToken: "Bearer " + localStorage.getItem("token"),
    //   allowedMimeType: [
    //     "image/png",
    //     "image/jpg",
    //     "image/jpeg",
    //     "image/webp",
    //     "image/svg",
    //   ],
    //   maxFileSize: 10 * 1024 * 1024,
    //   queueLimit: 10,
    //   //  autoUpload: true
    // });
    // this.initUploader();
    this.newContentForm = this.fb.group({
      category: ["Select", Validators.required],
      name: ["", Validators.required],
      file: [{}, Validators.required],
      description: ["", Validators.required],
      rating: [, Validators.required],
      userId: [],
    });
  }

  get f() {
    return this.newContentForm.controls;
  }
  // initUploader() {
  //   this.uploader.onWhenAddingFileFailed = (item, filter, options) => {
  //     this.ngFlashMessageService.showFlashMessage({
  //       // Array of messages each will be displayed in new line
  //       messages: ["Invalid file format"],
  //       dismissible: true,
  //       timeout: 8000,
  //       // Type of flash message, it defaults to info and success, warning, danger types can also be used
  //       type: "danger",
  //     });
  //   };
  //   this.uploader.onAfterAddingAll = (file) => {
  //     this.ngFlashMessageService.showFlashMessage({
  //       // Array of messages each will be displayed in new line
  //       messages: ["uploading"],
  //       dismissible: true,
  //       timeout: 8000,
  //       // Type of flash message, it defaults to info and success, warning, danger types can also be used
  //       type: "success",
  //     });
  //   };
  //   this.uploader.onAfterAddingFile = (file) => {
  //     file.withCredentials = false;
  //     this.uploader.uploadItem(file);
  //     this.uploader.onCompleteItem = (
  //       item: FileItem,
  //       response: string,
  //       status: number,
  //       headers
  //     ) => {
  //       var parsedRes = JSON.parse(response);
  //       if (parsedRes.imageUrl) {
  //         this.filePreArr.push(parsedRes.imageUrl);
  //         this.newContentForm.patchValue({
  //           imageUrl: this.filePreArr,
  //         });
  //       } else {
  //         this.uploader.removeFromQueue(item);
  //         this.ngFlashMessageService.showFlashMessage({
  //           // Array of messages each will be displayed in new line
  //           messages: ["error"],
  //           dismissible: true,
  //           timeout: 8000,
  //           // Type of flash message, it defaults to info and success, warning, danger types can also be used
  //           type: "danger",
  //         });
  //       }
  //       if (this.filePreArr.length === this.uploader.queue.length)
  //         this.ngFlashMessageService.showFlashMessage({
  //           // Array of messages each will be displayed in new line
  //           messages: ["Maximum limit reached"],
  //           dismissible: true,
  //           timeout: 8000,
  //           // Type of flash message, it defaults to info and success, warning, danger types can also be used
  //           type: "danger",
  //         });
  //     };
  //   };
  // }
  onSubmit() {
    const formData = new FormData();
    formData.append("category", this.newContentForm.value.category);
    formData.append("name", this.newContentForm.value.name);
    formData.append(
      "file",
      this.newContentForm.value.file,
      this.newContentForm.value.file.name
    );
    formData.append("description", this.newContentForm.value.description);
    formData.append("rating", this.newContentForm.value.rating);
    formData.append("userId", localStorage.getItem("userId"));
    this.authservice.createContent(formData).subscribe((response) => {
      this.newContentForm.reset();
      this.ngFlashMessageService.showFlashMessage({
        // Array of messages each will be displayed in new line
        messages: [response["message"]],
        dismissible: true,
        timeout: 8000,
        // Type of flash message, it defaults to info and success, warning, danger types can also be used
        type: "success",
      });
      this.authservice.contentEmitter.emit();
      this.router.navigate(["/home"]);
    });
  }
  rating(value) {
    this.numClass = value;
    this.newContentForm.patchValue({
      rating: value,
    });
  }
  onFileSelected(event) {
    const file = event.target.files[0];
    this.newContentForm.get("file").setValue(file);
  }
  ngOnDestroy() {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }
}
