import { Component, OnInit } from "@angular/core";
import {
  faHome,
  faSearch,
  faUserCircle,
  faUserFriends,
} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.scss"],
})
export class NavigationComponent implements OnInit {
  faHome = faHome;
  faSearch = faSearch;
  faUserCircle = faUserCircle;
  faUserFriends = faUserFriends;
  userRole: any;
  constructor() {}

  ngOnInit() {
    this.userRole = localStorage.getItem("userType");
  }
}
