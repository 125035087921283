import { Component, OnInit } from "@angular/core";
import {
  faHome,
  faShare,
  faSearch,
  faUserCircle
} from "@fortawesome/free-solid-svg-icons";
import { NewContent } from "../../newContent.modal";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-homepage",
  templateUrl: "./homepage.component.html",
  styleUrls: ["./homepage.component.scss"],
})
export class HomepageComponent implements OnInit {
  faShare = faShare;
  faHome = faHome;
  faSearch = faSearch;
  faUserCircle = faUserCircle;
  searchString: String = "";
  brightestStarts = [];
  queryData = {
    limit: 10,
    skip: 1,
    searchString: this.searchString,
    userId: localStorage.getItem('userId')
  }

  data: NewContent[] = [
    new NewContent(
      "Books",
      "Harry Potter",
      "Harry Potter despcribtion",
      "https://upload.wikimedia.org/wikipedia/commons/1/15/Recipe_logo.jpeg",
      5
    ),
    new NewContent(
      "Movies",
      "Harry Potter",
      "Harry Potter despcribtion",
      "https://upload.wikimedia.org/wikipedia/commons/1/15/Recipe_logo.jpeg",
      10
    ),
  ];

  constructor(private authService: AuthService) { }

  ngOnInit() {
    // this.queryData['userId'] = localStorage.getItem('userId')
    this.authService.getTopTenPosts(this.queryData).subscribe((response) => {
      this.brightestStarts = response.data;
    });
  }
}
