import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { AuthService } from "src/app/services/auth.service";
import { NgFlashMessageService } from "ng-flash-messages";
import { FileUploader, FileItem } from "ng2-file-upload";
import { environment } from "src/environments/environment";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { Router } from "@angular/router";
import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from "@angular/common";

@Component({
  selector: "app-new-post",
  templateUrl: "./new-post.component.html",
  styleUrls: ["./new-post.component.scss"],
})
export class NewPostComponent implements OnInit {
  faAngleLeft = faAngleLeft;
  circleDisplay: boolean = false;
  numClass: any = 0;
  newPostForm: FormGroup;
  submitted: boolean;
  ratingNo: number;
  uploader: FileUploader;
  filePreArr = [];
  fileData: { encoding: string; fieldname: string; mimetype: any; originalname: any; };
  constructor(
    private fb: FormBuilder,
    private authservice: AuthService,
    private ngFlashMessageService: NgFlashMessageService,
    private router: Router,
    public location: Location
  ) { }
  ngOnInit() {
    // this.uploader = new FileUploader({
    //   url: environment.apiUrl + "uploadFile",
    //   // authToken: "Bearer " + localStorage.getItem("token"),
    //   allowedMimeType: [
    //     "image/png",
    //     "image/jpg",
    //     "image/jpeg",
    //     "image/webp",
    //     "image/svg",
    //   ],
    //   maxFileSize: 10 * 1024 * 1024,
    //   queueLimit: 10,
    //   //  autoUpload: true
    // });
    // this.initUploader();
    this.newPostForm = this.fb.group({
      postUrl: ["", [Validators.required]],
      file: [{}, [Validators.required]],
      description: ["", [Validators.required]],
      rating: [, [Validators.required]],
      userId: []
    });
  }

  get f() {
    return this.newPostForm.controls;
  }
  // initUploader() {
  //   this.uploader.onWhenAddingFileFailed = (item, filter, options) => {
  //     this.ngFlashMessageService.showFlashMessage({
  //       // Array of messages each will be displayed in new line
  //       messages: ["Invalid file format"],
  //       dismissible: true,
  //       timeout: 8000,
  //       // Type of flash message, it defaults to info and success, warning, danger types can also be used
  //       type: "danger",
  //     });
  //   };
  //   this.uploader.onAfterAddingAll = (file) => {
  //     this.ngFlashMessageService.showFlashMessage({
  //       // Array of messages each will be displayed in new line
  //       messages: ["uploading"],
  //       dismissible: true,
  //       timeout: 8000,
  //       // Type of flash message, it defaults to info and success, warning, danger types can also be used
  //       type: "success",
  //     });
  //   };
  //   this.uploader.onAfterAddingFile = (file) => {
  //     file.withCredentials = false;
  //     this.uploader.uploadItem(file);
  //     this.uploader.onCompleteItem = (
  //       item: FileItem,
  //       response: string,
  //       status: number,
  //       headers
  //     ) => {
  //       var parsedRes = JSON.parse(response);
  //       if (parsedRes.imageUrl) {
  //         this.filePreArr.push(parsedRes.imageUrl);
  //         this.newPostForm.patchValue({
  //           imageUrl: this.filePreArr,
  //         });
  //       } else {
  //         this.uploader.removeFromQueue(item);
  //         this.ngFlashMessageService.showFlashMessage({
  //           // Array of messages each will be displayed in new line
  //           messages: ["error"],
  //           dismissible: true,
  //           timeout: 8000,
  //           // Type of flash message, it defaults to info and success, warning, danger types can also be used
  //           type: "danger",
  //         });
  //       }
  //       if (this.filePreArr.length === this.uploader.queue.length)
  //         this.ngFlashMessageService.showFlashMessage({
  //           // Array of messages each will be displayed in new line
  //           messages: ["Image uploaded"],
  //           dismissible: true,
  //           timeout: 8000,
  //           // Type of flash message, it defaults to info and success, warning, danger types can also be used
  //           type: "success",
  //         });
  //     };
  //   };
  // }
  onSubmit() {    
    let formData = new FormData();
    formData.append('file', this.newPostForm.value.file,this.newPostForm.value.file.name);
    formData.append('postUrl',this.newPostForm.value.postUrl)
    formData.append('description',this.newPostForm.value.description)
    formData.append('rating',this.newPostForm.value.rating)
    formData.append('userId',localStorage.getItem('userId'))
    this.circleDisplay = true;
    this.authservice.createPost(formData).subscribe((response) => {
      this.ngFlashMessageService.showFlashMessage({
        // Array of messages each will be displayed in new line
        messages: [response["message"]],
        dismissible: true,
        timeout: 8000,
        // Type of flash message, it defaults to info and success, warning, danger types can also be used
        type: "success",
      });
    this.authservice.postEmitter();
      this.router.navigate(["/home"]);
      this.newPostForm.reset();
    });
    }
  rating(value) {
    this.numClass = value;
    this.newPostForm.patchValue({
      rating: value,
    });
  }

  navigateBack() {
    this.location.back();
  }
  onFileSelected(event) {

    const file = event.target.files[0];
    this.newPostForm.get('file').setValue(file);
  }
}
