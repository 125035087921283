import { EventEmitter, Injectable, Output } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { retry, catchError } from "rxjs/operators";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { NgFlashMessageService } from "ng-flash-messages";
import { Router } from "@angular/router";

const BACKEND_URL = environment.apiUrl;
@Injectable({
  providedIn: "root",
})
export class AuthService {
  newuser;
  authToken: String;
  user: any;
  token: any;
  role: any;
  id: string;
  contentEmitter = new EventEmitter<String>();
  @Output() Post: EventEmitter<Object> = new EventEmitter();


  constructor(
    private http: HttpClient,
    private ngFlashMessageService: NgFlashMessageService,
    public router: Router
  ) { }

  // Http Headers
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PUT, PATCH, DELETE",
    }),
  };

  // POST
  registerUser(user) {
    return this.http.post(BACKEND_URL + "register", user); //, this.httpOptions)
  }

  loginUser(user) {
    return this.http
      .post<any>(BACKEND_URL + "login", user)
      .subscribe((data) => {
        this.token = data.token;
        this.id = data.id;
        this.role = data.role;
        if (data.status == 200) {
          localStorage.setItem("token", this.token);
          localStorage.setItem("userId", this.id);
          localStorage.setItem("userType", this.role);
          if (data["firstTime"] == false) {
            this.router.navigateByUrl("/foreword");
          } else if (data["role"] == 'user' && data["isBlocked"] == false) {
            this.router.navigateByUrl("/home");
          }
          else if (data["role"] == 'admin') {
            this.router.navigateByUrl("/admin");
          } else if (data["isBlocked"] == true) {
            alert('Your credential is temporarily blocked, please contact ADMIN')
          }
        } else {
          this.ngFlashMessageService.showFlashMessage({
            // Array of messages each will be displayed in new line
            messages: [data["message"]],
            dismissible: true,
            timeout: 300000,
            // Type of flash message, it defaults to info and success, warning, danger types can also be used
            type: "danger",
          });
        }
      });
  }

  forgetpassword(user) {
    return this.http.post<any>(BACKEND_URL + "forget-password", user);
  }
  forgetpasswordweb(user) {
    return this.http.post<any>(BACKEND_URL + "forgot-Password", user);
  }
  setNewPassword(password, token) {
    // let userdata = JSON.stringify(user);
    let userdata = password;
    return this.http.post<any>(
      BACKEND_URL + "new-password?key=" + token,
      userdata
      // this.httpOptions
    );
  }

  createContent(data) {
    return this.http.post<any>(
      BACKEND_URL + "createContent",
      data
    );
  }
  searchContent(data) {
    return this.http.get<any>(BACKEND_URL + "search/:searchText", data);
  }
  getContent(searchText: String) {
    return this.http.get<any>(BACKEND_URL + "AllContent");
  }
  getAllUsers() {
    return this.http.get<any>(BACKEND_URL + "getAllUsers");
  }
  getContentById(ContentId) {
    return this.http.get<any>(BACKEND_URL + "Content/" + ContentId);
  }
  updateRating(Contentid, rating) {
    // const obj = { rating: rating };
    return this.http.put(BACKEND_URL + "update-rating/" + Contentid, rating);
  }

  updateIsBlocked(userid, status) {
    let body = {
      userId: userid,
      isBlock: status
    }
    return this.http.put(BACKEND_URL + "blockUser/", body);
  }

  getTopTenPosts(data: object) {
    return this.http.post<any>(BACKEND_URL + "topContents", data);
  }
  getUserById(id) {
    return this.http.get(BACKEND_URL + "get-user/" + id);
  }

  logout() {
    this.token = null;
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("userType");
  }
  createPost(value) {
    return this.http.post(BACKEND_URL + "createPost", value);
  }
  getTopPosts() {
    return this.http.get(BACKEND_URL + "topposts");
  }
  likePost(postId, value) {
    return this.http.put(BACKEND_URL + "litOrUnlitPost/" + postId, value);
  }

  deleteContent(contentId) {
    return this.http.delete(BACKEND_URL + "deleteContent" + `?contentId=${contentId}`);
  }

  deletePost(postId) {
    return this.http.delete(BACKEND_URL + "deletePost" + `?Postid=${postId}`);
  }

  blockContent(contentId) {
    let userId = localStorage.getItem('userId');
    return this.http.put(BACKEND_URL + "blockContent" + `?postId=${contentId}` + `&blockedList=${userId}`, '');
  }

  blockPost(postId) {
    let userId = localStorage.getItem('userId');
    return this.http.put(BACKEND_URL + "blocpostbyId" + `?postId=${postId}` + `&blockedList=${userId}`, '');
  }

  blockUser(createdBy) {
    let userId = localStorage.getItem('userId');
    return this.http.put(BACKEND_URL + "blockUserbyId" + `?userId=${userId}` + `&blockedList=${createdBy}`, '');
  }

  // getLitandUnlitById(id) {
  //   return this.http.get<any>(BACKEND_URL + "getLitandUnlit/" + id);
  // }

  insertRatingfromNewUser(Contentid, rating) {
    // const obj = { rating: rating };
    return this.http.put(BACKEND_URL + "insertRatingfromNewUser/" + Contentid, rating);
  }
  getAllPosts() {
    let userId = localStorage.getItem('userId');
    return this.http.get(BACKEND_URL + `sortPostByRanking/0/0/?userId=${userId}`);
  }

  feedbackForm(data, id) {
    return this.http.post(BACKEND_URL + "feedbackForm/" + id, data);
  }
  postEmitter() {
    this.Post.emit();
  }
}
